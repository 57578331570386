import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

const sliders = document.querySelectorAll('.js-gallery-splide');

const options = {
  type: 'loop',
  arrows: false,
  pagination: false,
  perPage: 2,
  height: '500px',
  gap: '0.25rem',
  drag: 'free',
  focus: 'center',
  autoWidth: true,
};

if (sliders || sliders.length) {
  sliders.forEach((slide) => {
    const dataAttrDirection = slide.getAttribute('data-direction');
    if (!dataAttrDirection) {
      return;
    }

    let smBreaktpoint = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--responsive-sm');

    if (smBreaktpoint) {
      smBreaktpoint = smBreaktpoint.replace('px', '');
    }

    const breakpoints = {};
    breakpoints[smBreaktpoint] = {
      perPage: 1,
      padding: '1.5rem',
      drag: 'snap',
      pagination: true,
      height: '400px',
      autoScroll: {
        speed: 0,
      },
      autoWidth: false,
    };

    const autoScroll =
      smBreaktpoint >= window.innerWidth ? undefined : { AutoScroll };

    new Splide(slide, {
      ...options,
      breakpoints,
      autoScroll: {
        pauseOnHover: false,
        speed: dataAttrDirection,
      },
    }).mount(autoScroll);
  });
}
