import Splide from '@splidejs/splide';

if (document.querySelector('.js-lightbox-splide')) {
  const lighboxSplide = new Splide('.js-lightbox-splide', {
    arrows: true,
    gap: '1rem',
  }).mount();

  const galleryImages = document.querySelectorAll('.js-gallery-image');
  if (galleryImages) {
    galleryImages.forEach((image) => {
      image.addEventListener('click', (e) => {
        e.preventDefault();
        const imageID = image.getAttribute('data-gallery-image-id');

        if (!imageID) {
          return;
        }

        const lightboxImage = document.querySelector(
          `.js-lightbox-image[data-gallery-image-id="${imageID}"]`
        );

        jQuery('#exampleModal').on('shown.bs.modal', function () {
          lighboxSplide.go(
            Array.from(lightboxImage.parentNode.children).indexOf(lightboxImage)
          );
        });
      });
    });
  }
}
