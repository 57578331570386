import './../styles/main.scss';
import './countup.js';
import './projects-splide-slider.js';
import './gallery-1-splide-slider.js';
import './buildings.js';
import './gallery-lightbox-splide-slider.js';
import './gallery-filtering.js';
import './google-map.js';

window.addEventListener('scroll', () => {
  const header = document.querySelector('.js-header');
  if (!header) {
    return;
  }

  if (window.scrollY > 0) {
    header.classList.add('sticky');
  } else {
    header.classList.remove('sticky');
  }
});

jQuery(document).ready(function ($) {
  $(document).on('click', '.dropdown-menu', function (e) {
    $(window).on('scrollend', function () {
      $('.navbar-collapse').collapse('hide');
    });
  });
});

// // make it as accordion for smaller screens
// if (jQuery(window).width() < 992) {
//   jQuery('.dropdown-menu a').click(function (e) {
//     e.preventDefault();
//     if (jQuery(this).next('.submenu').length) {
//       jQuery(this).next('.submenu').toggle();
//     }
//     jQuery('.dropdown').on('hide.bs.dropdown', function () {
//       jQuery(this).find('.submenu').hide();
//     });
//   });
// }
