import { CountUp } from 'countup.js';

document.addEventListener('DOMContentLoaded', function () {
  const stats = document.querySelectorAll('.js-countup');
  if (!stats) {
    return;
  }

  stats.forEach((stat) => {
    let observer = new IntersectionObserver(function (entries, observer) {
      const countUp = new CountUp(stat, stat.getAttribute('data-stat'), {
        separator: ' ',
      });
      countUp.start();
    });
    observer.observe(stat);
  });
});
