import Isotope from 'isotope-layout';

const gallery = document.querySelector('.js-isotope-content');
if (gallery) {
  const isotope = new Isotope(gallery, {
    // options
    itemSelector: '.js-isotope-constent-single',
    //   layoutMode: 'vertical',
  });

  // bind filter button click
  const filters = document.querySelectorAll('.js-isotope-filter');
  filters.forEach((filter) => {
    filter.addEventListener('click', () => {
      const dataSlug = filter.getAttribute('data-slug');
      if (dataSlug === '*') {
        isotope.arrange({ filter: '*' });
      } else {
        isotope.arrange({
          filter: `[data-category="${dataSlug}"]`,
        });
      }

      gallery.scrollIntoView();
    });
  });
}
