import Splide from '@splidejs/splide';

if (document.querySelector('.js-projects-splide')) {
  new Splide('.js-projects-splide', {
    type: 'loop',
    autoplay: true,
    arrows: false,
    gap: '1rem',
  }).mount();
}
